import React from 'react'
import { Icon, Select } from 'antd'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { selectCompany } from '../../redux/companySelect/actions'
import { getCompanyById } from '../../utils'

@connect(({ accounts, companySelect }) => ({ accounts, companySelect }))
export default class Company extends React.Component {
  handleChange = value => {
    const { dispatch } = this.props
    dispatch(selectCompany(value))
  }

  render() {
    const {
      accounts: { data: companies },
      companySelect: { selected },
    } = this.props

    const selectedCompany = getCompanyById(companies, selected)
    const singleCompany = companies.length === 1

    const select = (
      <Select defaultValue={selected} value={selected} style={{ width: 160 }} disabled={companies.length === 0} onChange={this.handleChange} className="ml-2">
        {companies.length === 0 && (
          <Select.Option value={-1}>
            <FormattedMessage id="accounts.select_company" />
          </Select.Option>
        )}
        {companies.map(c => {
          return (
            <Select.Option key={c.id} value={c.id} company={c}>
              {c.name}
            </Select.Option>
          )
        })}
      </Select>
    )

    const text = <span className="ml-2">{selectedCompany ? selectedCompany.name : '...'}</span>

    return (
      <>
        <Icon type="profile" className="text-muted" style={{ fontSize: 16 }} />
        {singleCompany && text}
        {!singleCompany && select}
      </>
    )
  }
}
