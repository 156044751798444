import { all, takeLatest, takeEvery, put, select } from 'redux-saga/effects'
import { request } from 'ca-admin-core'

import { getAccountDetails } from '../../../api'
import actions from './actions'

const getCompanySelect = state => state.companySelect

function* LOAD() {
  const companySelect = yield select(getCompanySelect)
  yield put(request(getAccountDetails(companySelect.selected), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeEvery('company/save', LOAD)])
}
