import { core } from 'ca-admin-core'

import { ADMIN_ROLES } from 'utils/constants'

import auth from './auth'
import accounts from './accounts'
import dashboard from './dashboard'
import people from './people'
import billing from './billing'
import invites from './invites'
import settings from './settings'
import common from './common'

const { REACT_APP_BASE_AUTH_URL, REACT_APP_API_V1 } = process.env

const authConfig = {
  url: `${REACT_APP_BASE_AUTH_URL}${REACT_APP_API_V1}/login`,
  adminRoles: ADMIN_ROLES,
  overrideUi: true, // disable default login page
  extraParams: {
    // current_role: 'business',
  },
  transformPayload: payload => {
    if (!payload) return {}

    let transformedPayload = {}

    if (payload.access_token) {
      transformedPayload = {
        token: payload.access_token,
      }
    }

    if (payload.user) {
      transformedPayload = {
        ...transformedPayload,
        ...payload.user,
        name: `${payload.user.first_name} ${payload.user.last_name}`,
        roles: payload.user.roles.map(role => role.name),
        permissions: payload.user.permissions.map(permission => permission.name),
      }
    }

    if (payload.roles) {
      transformedPayload = {
        ...transformedPayload,
        roles: payload.roles.map(role => role.name),
      }
    }

    if (payload.permissions) {
      transformedPayload = {
        ...transformedPayload,
        permissions: payload.permissions.map(permission => permission.name),
      }
    }

    return transformedPayload
  },
}

export default [core(authConfig), auth, accounts, dashboard, people, billing, invites, settings, common]
