import actions from './actions'

const initialState = {
  loading: false,
  data: {},
}

export default function create(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.business_account_employee,
        },
        loading: false,
      }
    case actions.CREATE_ERROR:
      return {
        ...initialState,
        ...action.payload,
      }
    case actions.CREATE_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
