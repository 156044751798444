import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Company, Status } from 'modules/accounts'
import { Wallet } from 'modules/billing'

@connect(({ settings }) => ({ settings }))
export default class TopBar extends Component {
  render() {
    const {
      settings: { isMobileView },
    } = this.props

    return (
      <>
        <div className={isMobileView ? 'ml-2' : ''}>
          <Company />
        </div>
        {!isMobileView && (
          <div className="ml-5">
            <Wallet />
          </div>
        )}
        {!isMobileView && (
          <div className="ml-5">
            <Status />
          </div>
        )}
      </>
    )
  }
}
