import en from './locales/en'
import ro from './locales/ro'
import ru from './locales/ru'
import uk from './locales/uk'

export default {
  name: 'common',
  locales: {
    en,
    ro,
    ru,
    uk,
  },
}

export { default as TripsList } from './components/TripsList'
