import actions from './actions'

const initialState = {
  loading: false,
  data: {},
}

export default function details(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...initialState,
        loading: true,
      }
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.business_account_employee,
        },
      }
    case actions.ERROR:
      return {
        ...initialState,
      }
    case actions.RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
