import React from 'react'
import { Timeline } from 'antd'
import { FormattedMessage } from 'react-intl'

export default class RoutePoints extends React.Component {
  getColor = (index, array) => {
    if (index === 0) {
      return 'green'
    }

    if (index === array.length - 1) {
      return 'red'
    }

    return 'gray'
  }

  getColorCode = color => {
    switch (color) {
      case 'green':
        return '#46be8a'
      case 'red':
        return '#fb434a'
      default:
        return '#888'
    }
  }

  getText = color => {
    switch (color) {
      case 'green':
        return <FormattedMessage id="common.trip_details.pickup" />
      case 'red':
        return <FormattedMessage id="common.trip_details.dropoff" />
      default:
        return <FormattedMessage id="common.trip_details.pass" />
    }
  }

  render() {
    const { points } = this.props

    return (
      <Timeline className="mt-2">
        {points.map((el, index, array) => {
          const color = this.getColor(index, array)

          return (
            <Timeline.Item color={color} key={JSON.stringify(el)}>
              <div style={{ color: this.getColorCode(color), paddingTop: 2 }}>{this.getText(color)}</div>
              <div className="font-weight-bold">{el.name}</div>
              <div className="text-muted">{el.address_name}</div>
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }
}
