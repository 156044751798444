import { all, put, takeEvery, select } from 'redux-saga/effects'
import { request, setDynamicRoles } from 'ca-admin-core'

import { getAccounts } from '../../api'
import actions from './actions'

const getAccountsList = state => state.accounts

function* LOAD() {
  const apiGetAccounts = getAccounts()
  if (apiGetAccounts) {
    yield put(request(getAccounts(), actions.LOAD))
    yield put(setDynamicRoles([{ name: 'business' }]))
  }
}

function* SUCCESS() {
  const accounts = yield select(getAccountsList)

  if (accounts.data.length === 0) {
    yield put(setDynamicRoles([]))
  }
}

export default function* rootSaga() {
  yield all([LOAD(), takeEvery('auth/success', LOAD), takeEvery('account/create/success', LOAD), takeEvery(actions.SUCCESS, SUCCESS)])
}
