import { networkActions } from 'ca-admin-core'

const actions = {
  ...networkActions('people'),
}

export default actions

export function load(params) {
  return {
    type: actions.LOAD,
    payload: {
      params,
    },
  }
}
