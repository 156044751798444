import * as React from 'react'
import { Loadable } from 'ca-admin-core'
import { FormattedMessage } from 'react-intl'

import en from './locales/en'
import ro from './locales/ro'
import ru from './locales/ru'
import uk from './locales/uk'

import reducers from './redux/reducers'
import sagas from './redux/sagas'

export default {
  name: 'settings',
  menu: [
    {
      title: <FormattedMessage id="settings.menu.title" />,
      key: 'settings',
      icon: 'icmn icmn-cog',
      children: [
        {
          title: <FormattedMessage id="settings.menu.account" />,
          // key: 'settings.account',
          key: 'settings.account.edit',
          url: '/settings/account/edit',
          // children: [
          //   {
          //     title: <FormattedMessage id="settings.menu.account_edit" />,
          //     key: 'settings.account.edit',
          //     url: '/settings/account/edit',
          //   },
          //   {
          //     title: <FormattedMessage id="settings.menu.account_create" />,
          //     key: 'settings.account.create',
          //     url: '/settings/account/create',
          //   },
          // ],
        },
        {
          title: <FormattedMessage id="settings.menu.labels" />,
          key: 'settings.labels',
          url: '/settings/labels',
        },
      ],
      roles: ['business'],
    },
  ],
  routes: [
    {
      path: '/settings/labels',
      component: Loadable(() => import('./pages/labels')),
      exact: true,
    },
    {
      path: '/settings/labels/:id',
      component: Loadable(() => import('./pages/labels/details')),
      exact: true,
      modal: true,
    },
    {
      path: '/settings/account/create',
      component: Loadable(() => import('./pages/account/create')),
      exact: true,
    },
    {
      path: '/settings/account/edit',
      component: Loadable(() => import('./pages/account/edit')),
      exact: true,
    },
  ],
  locales: {
    en,
    ro,
    ru,
    uk,
  },
  reducers,
  sagas,
}

export { load as loadLabels } from './redux/labels/list/actions'
