import { getToken, net } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, postJson, destroy } = net

export const getInvites = (companyId, params) => get(`${API_URL}/${companyId}/invite-codes`, params, getToken())
export const getInviteDetails = (companyId, id) => get(`${API_URL}/${companyId}/invite-codes/${id}`, null, getToken())
export const createInvite = (companyId, params) => postJson(`${API_URL}/${companyId}/invite-codes`, params, getToken())
export const destroyInvite = (companyId, id) => destroy(`${API_URL}/${companyId}/invite-codes/${id}`, null, getToken())
export const updateInvite = (companyId, id, params) => postJson(`${API_URL}/${companyId}/invite-codes/${id}`, params, getToken())
