import { all, takeLatest, takeEvery, put, select } from 'redux-saga/effects'
import { request } from 'ca-admin-core'

import { getPersonDetails } from '../../api'
import actions, { load } from './actions'

const getCompanySelect = state => state.companySelect

let cachedParams = null

function* LOAD({ payload: { id } }) {
  cachedParams = id
  const companySelect = yield select(getCompanySelect)
  yield put(request(getPersonDetails(companySelect.selected, id), actions.LOAD))
}

function* REFRESH() {
  if (cachedParams) {
    yield put(load(cachedParams))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD), takeEvery('people/update/success', REFRESH)])
}
