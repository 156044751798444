import { all, takeEvery, select, put } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'

import actions, { setCompany, resetCompany, saveCompany } from './actions'

const getCompanySelect = state => state.companySelect
const getAccounts = state => state.accounts

function* SELECT_DEFAULT() {
  const { data: companies } = yield select(getAccounts)
  const companySelect = yield select(getCompanySelect)
  let { selected } = companySelect

  if (selected === -1 && companies.length > 0) {
    selected = companies[0].id
  }

  if (selected !== -1) {
    yield put(setCompany(selected))
  }
}

function* SET_COMPANY({ payload: { id } }) {
  yield store.set(`app.company.selected`, id)
  yield put(saveCompany(id))
}

function* SELECT_COMPANY({ payload: { id }, intl }) {
  yield put(setCompany(id))

  yield notification.success({
    message: intl.formatMessage({ id: 'common.notifications.done' }),
    description: intl.formatMessage({ id: 'accounts.company_changed' }),
  })
}

function* RESET() {
  yield store.remove('app.company.selected')
  yield put(resetCompany())
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SELECT_COMPANY, SELECT_COMPANY), takeEvery(actions.SET_COMPANY, SET_COMPANY), takeEvery('accounts/success', SELECT_DEFAULT), takeEvery('user/LOGOUT', RESET)])
}
