import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'

import { updateInvite } from '../../api'
import actions from './actions'

const getCompanySelect = state => state.companySelect

function* UPDATE({ payload: { id, params } }) {
  const companySelect = yield select(getCompanySelect)
  yield put(request(updateInvite(companySelect.selected, id, params), actions.UPDATE))
}

function* SUCCESS({ intl }) {
  yield notification.success({
    message: intl.formatMessage({ id: 'common.notifications.done' }),
    description: intl.formatMessage({ id: 'invites.notifications.updated' }),
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPDATE, UPDATE), takeLatest(actions.UPDATE_SUCCESS, SUCCESS)])
}
