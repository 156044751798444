import React from 'react'
import { Row, Col, Tag } from 'antd'
import { FormattedMessage } from 'react-intl'

import RoutePoints from './RoutePoints'

export default class TripDetails extends React.Component {
  render() {
    const {
      trip: { route_points: points, driver, car, image },
    } = this.props

    const { first_name: name = 'n / a' } = driver || {}
    const { registration_nb: plate, brand, model } = car || {}

    return (
      <div style={{ padding: 10 }}>
        <Row gutter={32}>
          <Col xs={24} md={9} className="mb-4">
            <RoutePoints points={points} />
            {/* <Button disabled>
              <FormattedMessage id="common.trip_details.download_receipt" />
            </Button> */}
          </Col>

          <Col xs={24} md={7} className="mb-4">
            <div>
              <div className="text-muted">
                <FormattedMessage id="common.trip_details.driver" />
              </div>
              <div>{name}</div>
            </div>

            <div className="mt-4">
              <div className="text-muted">
                <FormattedMessage id="common.trip_details.car" />
              </div>
              {brand && (
                <div>
                  {brand.name} {model.name}
                </div>
              )}
              {!brand && <div>n / a</div>}
              {plate && <Tag>{plate}</Tag>}
            </div>

            <div className="mt-4">
              <div className="text-muted">
                <FormattedMessage id="common.trip_details.class" />
              </div>
              <div>Standard</div>
            </div>

            {/* <div className="mt-4">
              <div className="text-muted">
                <FormattedMessage id="common.trip_details.duration" />
              </div>
              <div>19m 14s</div>
            </div> */}
          </Col>

          <Col xs={24} md={8} className="mb-4">
            <img src={image} alt="trip map" style={{ width: '100%' }} />
          </Col>
        </Row>
      </div>
    )
  }
}
