import { all, takeEvery, put, select } from 'redux-saga/effects'
import { request } from 'ca-admin-core'

import { getWallet } from '../../api'
import actions, { load } from './actions'

const getCompanySelect = state => state.companySelect

function* LOAD({ payload: { params } }) {
  const companySelect = yield select(getCompanySelect)
  yield put(request(getWallet(companySelect.selected, params), actions.LOAD))
}

function* COMPANY_SET() {
  yield put(load())
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD), takeEvery('company/save', COMPANY_SET)])
}
