import { all, takeEvery, put } from 'redux-saga/effects'
import { request } from 'ca-admin-core'

import { getTransactionTypes } from '../../api'
import actions from './actions'

function* LOAD({ payload: { params } }) {
  yield put(request(getTransactionTypes(params), actions.LOAD))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD)])
}
