import { all, takeEvery, put, select } from 'redux-saga/effects'
import { request } from 'ca-admin-core'

import { getOrders } from '../../api'
import actions, { load } from './actions'

const getCompanySelect = state => state.companySelect

let cachedParams = null

function* LOAD({ payload: { params } }) {
  cachedParams = params
  const companySelect = yield select(getCompanySelect)

  if (!companySelect.selected || companySelect.selected === -1) {
    return
  }

  yield put(request(getOrders(companySelect.selected, params), actions.LOAD))
}

function* COMPANY_SET() {
  if (cachedParams) {
    yield put(load(cachedParams))
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD), takeEvery('company/save', COMPANY_SET)])
}
