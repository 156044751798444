const actions = {
  SET_COMPANY: 'company/set',
  SELECT_COMPANY: 'company/select',
  SAVE_COMPANY: 'company/save',
  RESET_COMPANY: 'company/reset',
}

export default actions

export function setCompany(id) {
  return {
    type: actions.SET_COMPANY,
    payload: {
      id,
    },
  }
}

export function selectCompany(id) {
  return {
    type: actions.SELECT_COMPANY,
    payload: {
      id,
    },
  }
}

export function saveCompany(id) {
  return {
    type: actions.SAVE_COMPANY,
    payload: {
      id,
    },
  }
}

export function resetCompany() {
  return {
    type: actions.RESET_COMPANY,
    payload: {},
  }
}
