import * as React from 'react'
import { Loadable } from 'ca-admin-core'
import { FormattedMessage } from 'react-intl'

import en from './locales/en'
import ro from './locales/ro'
import ru from './locales/ru'
import uk from './locales/uk'

import reducers from './redux/reducers'
import sagas from './redux/sagas'

export default {
  name: 'people',
  menu: [
    {
      title: <FormattedMessage id="people.menu.title" />,
      key: 'people',
      url: '/people',
      icon: 'icmn icmn-user',
      roles: ['business'],
    },
  ],
  routes: [
    {
      path: '/people',
      component: Loadable(() => import('./pages')),
      exact: true,
    },
    {
      path: '/people/:id',
      component: Loadable(() => import('./pages/details')),
      exact: true,
    },
  ],
  locales: {
    en,
    ro,
    ru,
    uk,
  },
  reducers,
  sagas,
}

export { load as loadPeople } from './redux/list/actions'
export { default as PersonStatus } from './components/Status'
export { fullName } from './utils'
