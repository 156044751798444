import labels from './labels/list/reducers'
import createLabel from './labels/create/reducers'
import destroyLabel from './labels/destroy/reducers'
import updateLabel from './labels/update/reducers'

import createAccount from './account/create/reducers'
import accountDetails from './account/details/reducers'
import updateAccount from './account/update/reducers'

export default {
  labels,
  createLabel,
  destroyLabel,
  updateLabel,
  createAccount,
  accountDetails,
  updateAccount,
}
