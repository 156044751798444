import React from 'react'
import { Badge } from 'antd'
import { connect } from 'react-redux'

import { getCompanyById } from '../../utils'

@connect(({ accounts, companySelect }) => ({ accounts, companySelect }))
export default class Status extends React.Component {
  render() {
    const {
      companySelect: { selected },
      accounts: { data: companies },
    } = this.props

    if (selected === -1 || !companies.length) {
      return null
    }

    const company = getCompanyById(companies, selected)

    const { status } = company

    const color = status === 'active' ? 'green' : 'red'

    return (
      <>
        <Badge color={color} />
        <span className="text-muted">{status}</span>
      </>
    )
  }
}
