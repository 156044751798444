import { all, takeEvery, takeLatest, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { request, setDynamicRoles } from 'ca-admin-core'

import { createAccount } from '../../../api'
import actions from './actions'

function* CREATE({ payload: { params } }) {
  yield put(request(createAccount(params), actions.CREATE))
}

function* SUCCESS({ intl }) {
  yield notification.success({
    message: intl.formatMessage({ id: 'common.notifications.done' }),
    description: intl.formatMessage({ id: 'settings.account.notifications.created' }),
  })

  yield put(setDynamicRoles([{ name: 'business' }]))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE, CREATE), takeLatest(actions.CREATE_SUCCESS, SUCCESS)])
}
