import people from './list/reducers'
import personDetails from './details/reducers'
import addPerson from './create/reducers'
import personTrips from './trips/reducers'
import updatePerson from './update/reducers'
import removePerson from './destroy/reducers'

export default {
  people,
  personDetails,
  addPerson,
  personTrips,
  updatePerson,
  removePerson,
}
