import React from 'react'
import { Badge } from 'antd'

const Status = props => {
  const { active } = props

  return (
    <>
      <Badge color={active === 'active' ? 'green' : 'gray'} />
      <span className={active === 'active' ? '' : 'text-muted'}>{active}</span>
    </>
  )
}

export default Status
