import { getToken, net } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, postJson, destroy } = net

export const getLabels = (companyId, params) => get(`${API_URL}/${companyId}/labels`, params, getToken())
export const createLabel = (companyId, params) => postJson(`${API_URL}/${companyId}/labels`, params, getToken())
export const destroyLabel = (companyId, id) => destroy(`${API_URL}/${companyId}/labels/${id}`, null, getToken())
export const updateLabel = (companyId, id, params) => postJson(`${API_URL}/${companyId}/labels/${id}`, params, getToken())

export const createAccount = params => postJson(`${API_URL}`, params, getToken())
export const getAccountDetails = companyId => get(`${API_URL}/${companyId}`, null, getToken())
export const updateAccount = (companyId, params) => postJson(`${API_URL}/${companyId}`, params, getToken())
