import labels from './labels/list/sagas'
import createLabel from './labels/create/sagas'
import destroyLabel from './labels/destroy/sagas'
import updateLabel from './labels/update/sagas'

import createAccount from './account/create/sagas'
import accountDetails from './account/details/sagas'
import updateAccount from './account/update/sagas'

export default [labels(), createLabel(), destroyLabel(), updateLabel(), createAccount(), accountDetails(), updateAccount()]
