import React, { Component } from 'react'
import { Admin } from 'ca-admin-core'

import modules from 'modules'

import TopBar from 'components/TopBar'

import 'assets/theme.scss'

export default class App extends Component {
  render() {
    const extra = (
      <>
        <TopBar />
      </>
    )

    return <Admin title="Business Dash" modules={modules} topBarExtra={extra} />
  }
}
