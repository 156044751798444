import React from 'react'
import { DatePicker, Select, Icon } from 'antd'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { DataTable } from 'ca-admin-core'

import { fullName } from 'utils'

import { loadPeople } from 'modules/people'

import TripDetails from './TripDetails'
import data from './data.json'

const strong = title => <span className="font-weight-bold">{title}</span>

@injectIntl
@connect(({ settings, people }) => ({ settings, people }))
export default class TripsList extends React.Component {
  state = {
    closed_at_begin_date: null,
    closed_at_end_date: null,
    person: 'all',
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(loadPeople({ limit: 1000 }))
  }

  renderExpandIcon = props => {
    const type = props.expanded ? 'caret-down' : 'caret-right'

    return <Icon style={{ fontSize: 10 }} type={type} />
  }

  handleDateChange = range => {
    this.setState({
      closed_at_begin_date: range[0],
      closed_at_end_date: range[1],
    })
  }

  handlePersonChange = value => {
    this.setState({
      person: value || 'all',
    })
  }

  getFilters = () => {
    const { closed_at_begin_date, closed_at_end_date, person } = this.state
    const { employee } = this.props

    const format = 'YYYY-MM-DD'

    let filters = {}

    if (closed_at_begin_date) {
      filters = {
        closed_at_begin_date: closed_at_begin_date.format(format),
      }
    }

    if (closed_at_end_date) {
      filters = {
        ...filters,
        closed_at_end_date: closed_at_end_date.format(format),
      }
    }

    if (employee) {
      filters = {
        ...filters,
        employee,
      }
    }

    if (person && person !== 'all') {
      filters = {
        ...filters,
        employee: person,
      }
    }

    return filters
  }

  handleClearFilters = () => {
    this.setState({
      closed_at_begin_date: null,
      closed_at_end_date: null,
      person: 'all',
    })
  }

  render() {
    const {
      intl,
      settings: { isMobileView },
      people,
      hideClient,
      ...rest
    } = this.props

    const { closed_at_begin_date, closed_at_end_date, person } = this.state

    const columns = [
      {
        title: strong(<FormattedMessage id="common.trips_list.columns.date_time" />),
        dataIndex: 'created_at',
        sorter: false,
        width: 120,
        render: date => {
          date = moment.utc(date).local()

          return (
            <>
              <div>{strong(date.format('YYYY-MM-DD'))}</div>
              <div className="text-muted font-weight-bold">{date.format('HH:mm')}</div>
            </>
          )
        },
      },
      ...(!hideClient
        ? [
            {
              title: strong(<FormattedMessage id="common.trips_list.columns.person" />),
              dataIndex: 'client',
              sorter: false,
              render: client => {
                return (
                  client && (
                    <>
                      <div>{fullName(client)}</div>
                      <div className="text-muted mt-1">{client.phone}</div>
                    </>
                  )
                )
              },
            },
          ]
        : []),
      {
        title: strong(<FormattedMessage id="common.trips_list.columns.pickup" />),
        sorter: false,
        render: (_, record) => {
          const point = record.route_points[0]
          const { name, address_name: address } = point

          return (
            <>
              <div>{name}</div>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="text-muted">
                {address}
              </div>
            </>
          )
        },
      },
      {
        title: strong(<FormattedMessage id="common.trips_list.columns.dropoff" />),
        ellipsis: true,
        sorter: false,
        render: (_, record) => {
          const point = record.route_points[record.route_points.length - 1]
          const { name, address_name: address } = point

          return (
            <>
              <div>{name}</div>
              <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="text-muted">
                {address}
              </div>
            </>
          )
        },
      },
      {
        title: strong(<FormattedMessage id="common.trips_list.columns.cost" />),
        dataIndex: 'final_price',
        render: price => (price ? strong(`${price} lei`) : <div className="text-muted">n / a</div>),
        sorter: false,
        width: 120,
      },
    ]

    const search = (
      <>
        {!hideClient && (
          <Select
            value={person}
            style={{ minWidth: 200 }}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={this.handlePersonChange}
            allowClear
          >
            <Select.Option value="all">{intl.formatMessage({ id: 'common.trips_list.filters.all_people' })}</Select.Option>
            {people.data.map(p => (
              <Select.Option key={p.id} value={p.user_id}>
                {fullName(p)}
              </Select.Option>
            ))}
          </Select>
        )}
        <DatePicker.RangePicker className={hideClient ? '' : 'ml-2'} value={[closed_at_begin_date, closed_at_end_date]} onChange={this.handleDateChange} />
      </>
    )

    return (
      <div style={isMobileView ? { overflowX: 'auto' } : {}}>
        <DataTable
          style={isMobileView ? { width: 900 } : {}}
          columns={columns}
          dataSource={{ data, loading: false }}
          loadAction={() => ({ type: 'dummy' })}
          filters={this.getFilters()}
          customSearch={search}
          expandRowByClick
          expandIcon={this.renderExpandIcon}
          expandedRowRender={trip => <TripDetails trip={trip} />}
          onClearFilters={this.handleClearFilters}
          {...rest}
        />
      </div>
    )
  }
}
