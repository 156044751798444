import { getToken, net } from 'ca-admin-core'
import { API_URL } from 'utils/constants'

const { get, postJson, destroy } = net

export const getPeople = (companyId, params) => get(`${API_URL}/${companyId}/employees`, params, getToken())
export const getPersonDetails = (companyId, id) => get(`${API_URL}/${companyId}/employees/${id}`, null, getToken())
export const addPerson = (companyId, params) => postJson(`${API_URL}/${companyId}/employees`, params, getToken())
export const updatePerson = (companyId, id, params) => postJson(`${API_URL}/${companyId}/employees/${id}`, params, getToken())
export const removePerson = (companyId, id) => destroy(`${API_URL}/${companyId}/employees/${id}`, null, getToken())
export const getOrders = (companyId, params) => get(`${API_URL}/${companyId}/orders`, params, getToken())
