import invites from './list/reducers'
import inviteDetails from './details/reducers'
import createInvite from './create/reducers'
import destroyInvite from './destroy/reducers'
import updateInvite from './update/reducers'

export default {
  invites,
  inviteDetails,
  createInvite,
  destroyInvite,
  updateInvite,
}
