import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { request } from 'ca-admin-core'

import { destroyInvite } from '../../api'
import actions from './actions'

const getCompanySelect = state => state.companySelect

function* DESTROY({ payload: { id } }) {
  const companySelect = yield select(getCompanySelect)
  yield put(request(destroyInvite(companySelect.selected, id), actions.DESTROY))
}

function* SUCCESS({ intl }) {
  yield notification.success({
    message: intl.formatMessage({ id: 'common.notifications.done' }),
    description: intl.formatMessage({ id: 'invites.notifications.removed' }),
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DESTROY, DESTROY), takeLatest(actions.DESTROY_SUCCESS, SUCCESS)])
}
