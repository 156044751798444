import store from 'store'
import actions from './actions'

const STORED_COMPANY = initialState => {
  const company = {}

  Object.keys(initialState).forEach(key => {
    const item = store.get(`app.company.${key}`)
    company[key] = typeof item !== 'undefined' ? item : initialState[key]
  })

  return company
}

const initialState = {
  ...STORED_COMPANY({
    selected: -1,
  }),
}

export default function companySelectReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SAVE_COMPANY:
      return {
        ...state,
        selected: action.payload.id,
      }
    case actions.RESET_COMPANY:
      return {
        ...state,
        selected: -1,
      }
    default:
      return state
  }
}
